// Announcement
var announcementCookie = "announcement";
function init() {
    
    $('#announcement__close').click(function () {
        console.log(moment(date).format('YYYY-MM-DD , h:mm:ss a '));
        var date = $('#createdDate').val();
        var date2 = new Date();
        //var date = "2019-05-17 16:00:00"
        $(".announcement").addClass('announcement--hide');
        
      //NEed to specify PM 
        Cookies.set(announcementCookie, { createdDate: moment(date2, 'YYYY-MM-DD ,h:mm:ss A').format('YYYY-MM-DD , h:mm:ss a') }, {
            expires: 365,
            
           
        });

    });


};


function defer(method) {
    if (window.jQuery) {
        console.log('All loaded'); 
        method();

    }
    else {
        console.log("Not loaded");
        setTimeout(function () { defer(method) }, 50);
    }
}
defer(function () {
    init();
});