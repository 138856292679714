var listingCount = 0;
function getEvents(skipNumber) {

    //Need to add in multiple filters , ignore for now
    //var filter = $("#currentFilter").val();
    var eventType = $("#eventType").val(); 
    var showPastEvents = $("#showPastEvents").val(); 
    var fromDate = $("#fromDate").val(); 

    var toDate = $("#toDate").val();
    console.log("Date: %o ", toDate);
    fromDate = fromDate.replaceAll("-", "/");
    toDate = toDate.replaceAll("-", "/");
  
    $.ajax({
        method: "GET",
        url: "/Umbraco/surface/Event/GetEvents",
        data: { skipNumber: skipNumber, eventType: eventType, toDate: toDate, fromDate: fromDate, showPast: showPastEvents }
    }).done(function (res) {
     
        var count = res.events.length;
        listingCount = listingCount + count;
     
        console.log("Results: %o", res);

        var source = document.getElementById("eventsListing-template").innerHTML;

        var template = Handlebars.compile(source);
        var html = template(res.events);
        if (res.events.length > 0) {


            if (skipNumber == 0) {
                $("#eventsListing").html(html);
            }
            else {

                $("#eventsListing").append(html);

            }
        }
        else {
            $("#eventsListing").html("<span class='meetings-listing__empty-message'> No events to show </span>");
        }
        if (res.end == true || count == 0) {
            $("#loadMoreEvents").hide();
        }
        else {
            $("#loadMoreEvents").show();
        }

        //$('#calendar').clndr({
        //    template: $('#calendar-template').html(),
        //    events: res.events,
        //    clickEvents: {
        //        click: function (target) {
        //            console.log(target.events.length);
        //        },
        //        onMonthChange: function (month) {
        //            console.log('you just went to ' + month.format('MMMM, YYYY'));
        //        }
        //    },
        //    doneRendering: function () {
        //        console.log('this would be a fine place to attach custom event handlers.');
        //    }
        //});

    });

}
function getAllEvents() {
    var showPastEvents = $("#showPastEvents").val(); 
    $.ajax({
        method: "GET",
        url: "/Umbraco/surface/Event/GetAllEvents",
        data: { showPast: showPastEvents }
    }).done(function (res) {
        console.log("Results of all events: %o", res.events);
        $('#calendar').clndr({
            template: $('#calendar-template').html(),
            events: res.events,
            clickEvents: {
                click: function (target) {
                                if (target.events.length) {
                                    var dayscontainer = $('.days-container');
                               
                                
                                    console.log("Days: %o", dayscontainer[0]);
                                    dayscontainer.addClass("show-events");

                                    $('.x-button').click(function () {

                                        dayscontainer.removeClass('show-events');
                                        $(".day").removeClass("active");
                                        $("#fromDate").val("");
                                        $("#toDate").val(""); 
                                        getEvents(0);
                        });
                       // dayscontainer.toggleclass('show-events', true);
                       
                    }
                    var dateClicked = target.date._i; 
                 

                    var item = $(".calendar-day-" + dateClicked); 

                    if (item.hasClass("active")) {
                        item.removeClass("active");
                        $("#fromDate").val("");
                        $("#toDate").val(""); 
                    }
                    else {
                        $(".day").removeClass("active");
                        item.addClass("active");
                        $("#fromDate").val(dateClicked);
                        $("#toDate").val(dateClicked); 
                    }
                   

                  
                    getEvents(0);
                    
                },
                onMonthChange: function (month) {
                    console.log('you just went to ' + month.format('MMMM, YYYY'));
                }
            },
            doneRendering: function () {
                console.log('this would be a fine place to attach custom event handlers.');
            }
        });

        //$('#mini-clndr').clndr({
        //    template: $('#mini-clndr-template').html(),
        //    events: res.events,
        //    clickevents: {
        //        click: function (target) {
        //            if (target.events.length) {
        //                var dayscontainer = $('#mini-clndr').find('.days-container');
        //                dayscontainer.toggleclass('show-events', true);
        //                $('#mini-clndr').find('.x-button').click(function () {
        //                    dayscontainer.toggleclass('show-events', false);
        //                });
        //            }
        //        }
        //    },
        //    adjacentdayschangemonth: true,
        //    forcesixrows: true
        //});

    });
}
function initEvents() {

    getEvents(0);
    getAllEvents();
    //var date = new Date('2021.02.02').getTime() / 1000
    //var test = 1519851600000 * 1000; 
    //console.log("Date: %o", test);
    //var myEvents = [{
    //    title: "Event Title 1",
    //    date: 1611964800000,
    //    link: "jqueryscript.net"
    //},
    //    {
    //        title: "Event Title 3",
    //        date: 1612051200000,
    //        link: "jqueryscript.net"
    //    },
    //{
    //    title: "Event Title 2",
    //    date: 1611964800000,
    //    link: "jqueryscript.net"
    //}];

    //$("#calendar").MEC({
    //    events: myEvents
    //});
    
 


 

}
$('#loadMoreEvents').unbind('click').click(function () {
   
    getEvents(listingCount);

});


$("#eventType").change(function () {
    listingCount = 0;
    getEvents(0);
});

$('#filterButtonEvent').click(function () {
    listingCount = 0;
    getEvents(0);
})

