$('.panels-toolkitGrid').isotope({
    // options...
    itemSelector: '.grid-item'
});

function defer(method) {
    if (window.jQuery) {
        method();
    } else {
        setTimeout(function() { defer(method) }, 50);
    }
}
var $tools = null;
defer(function() {
    $tools = $('.panels-toolkitGrid').isotope({
        // options
        itemSelector: '.grid-item',
        // layoutMode: 'fitRows',
        layoutMode: 'masonry',
        percentPosition: true
    });
    $('.btn-filter').click(function(event) {
        if ($(this).hasClass('active')) {
            event.preventDefault();
             $('.btn-filter').removeClass('active');
             $tools.isotope({ filter: '*' });
             
         } else {
        
            console.log('Filter clicked');
            event.preventDefault();
            $('.btn-filter').removeClass('active');
            $(this).toggleClass('active');
            var filterClasses = "." + $(this).attr('data-classname');
            console.log('Filter clicked : %o', filterClasses);
            $tools.isotope({ filter: filterClasses });
         }
    });
});
