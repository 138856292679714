var listingCount = 0;
function getPublications(skipNumber) {

    //Need to add in multiple filters , ignore for now
    //var filter = $("#currentFilter").val();
    var pubType = $("#publicationType").val(); 
    var topic = $("#publicationSpeciality").val(); 
    var status = $('#publicationStatus').val();
    var fromDate = $("#fromDate").val(); 
    var keyword = $("#publicationKeyword").val(); 

    var toDate = $("#toDate").val();
    //var complete = $("#publicationComplete").val();
    var showIncomplete = $("#publicationIncomplete").val();
    if (showIncomplete == "true") {
        status = "ShowIncomplete";
    }
   // var showIncomplete = $("#showIncomplete").val();
    fromDate = fromDate.replaceAll("-", "/");
    toDate = toDate.replaceAll("-", "/");
  
    $.ajax({
        method: "GET",
        url: "/Umbraco/surface/Publication/GetPublications",
        data: { skipNumber: skipNumber, publicationType: pubType, topic: topic, toDate: toDate, fromDate: fromDate,status: status, keyword: keyword }
    }).done(function (res) {
     
        var count = res.publications.length;
        listingCount = listingCount + count;
     
        console.log("Results: %o", res);
        var source = document.getElementById("publicationListing-template").innerHTML;
        var template = Handlebars.compile(source);
        var html = template(res.publications);
        if (skipNumber == 0) {
            $("#publicationListing").html(html);
        }
        else {
           // var scroll = document.getElementById("scrollPoint");
            
            //if (scroll != null) {
            //    document.getElementById("scrollPoint").remove();
            //}
          //  $("#publicationListing").append("<div id='scrollPoint'> </div>");
          //  var aTag = $("#scrollPoint");
       //     $('html,body').animate({ scrollTop: aTag.offset().top }, 'fast');
            $("#publicationListing").append(html);
           
        }

        //paginatePublications('publicationListing', 'ul',count);
        //$(function () {
        //    $(".paginationBottomHolder").pagination({
        //        items: count,
        //        itemsOnPage: 10
        //    });
        //});
        if (res.end == true || count == 0) {
            $("#loadMore").hide();
        }
        else {
            $("#loadMore").show();
        }


    });

}
function initPublications() {

    getPublications(0);
}
$('#loadMore').unbind('click').click(function () {
   
    getPublications(listingCount);

});

$('.filter-row__option').click(function () {
    //alert("clicked");
    if ($(this).hasClass("filter-row__option--active")) {
        $(this).removeClass("filter-row__option--active");
        $("#currentFilter").val("None");
        listingCount = 0;
        getCaseStudies(0);
    }
    else {
        $(".filter-row__option").removeClass("filter-row__option--active");
        $(this).addClass("filter-row__option--active");
        var currentFilter = $(this).attr("data-val");
        $("#currentFilter").val(currentFilter);
        listingCount = 0;
        getCaseStudies(0);
    }

});

$('.filter-row__filter--all').click(function () {
    $('.filter-row__option').removeClass("filter-row__option--active");
    $("#currentFilter").val("None");
    listingCount = 0;
    getCaseStudies(0);
})
$("#publicationType").change(function () {
    listingCount = 0;
    getPublications(0);
});
$("#publicationSpeciality").change(function () {
    listingCount = 0;
    getPublications(0);
});
$("#publicationStatus").change(function () {
    listingCount = 0;
    getPublications(0);
});
$('#filterButton').click(function () {
    listingCount = 0;
    getPublications(0);
})
function paginatePublications(elId, container,items) {
    console.log("TESTING");
    var per_page = 1;
    var page_nums_to_show = 2;
   
    // Allow the container to be somehting other than a ul, but default
    if (typeof container === 'undefined') { container = 'ul'; }

    var outer = $('#' + elId);
    console.log("Outer: %o", outer);
    //do it anyway to get the 1-N of N text (?)
    outer.simplePagination({
        items: items,
        itemsOnPage: per_page
    });

    //but hide the pagination and total if only one page
    if (outer.find(container).children().length <= per_page)
        outer.find('.pagination').hide();
}