$(function () {
    menu_trigger = $('.navbar-toggle');
    nav = $('.navbar');
    body = $('body');



    menu_trigger.click(function () {
        console.log("Nav clicked");
        nav.toggleClass("navbar--active");
        //$(this).toggleClass('menu-trigger--active');
        //nav.toggleClass('navbar--active');
        //// $('body').toggleClass('body--overlay');
        if ($(this).attr('aria-expanded') === 'true') {
            $(this).attr('aria-expanded', 'false');
        } else {
            $(this).attr('aria-expanded', 'true');
        }
    });

    $(document).click(function (e) {
        if (!$(menu_trigger).is(e.target)) {
            menu_trigger.removeClass('menu-trigger--active');
            nav.removeClass('navbar--active');
        }
    });

    $('.nav__dropdown-toggle').on('click', function () {
        $(this).toggleClass('nav__dropdown-toggle--active');
        $(this).nextAll('.nav__dropdown').toggleClass('nav__dropdown--active');
    });
    $('.mobile__nav-title').on('click', function () {
        $(this).toggleClass('mobile__nav-title--active');
        $(this).nextAll('.nav__dropdown').toggleClass('nav__dropdown--active');
    });
})