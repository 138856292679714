$(function(){
        var main_nav = $('.navbar');

        if ($(window).width() > 992) {
            main_nav.sticky({
                topSpacing:0
            });
            main_nav.on('sticky-start', function() {
                main_nav.addClass('navbar--sticky');
            });
            main_nav.on('sticky-end', function() {
                main_nav.removeClass('navbar--sticky');
            });
        }
})