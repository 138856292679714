

$('#searchFromDate').change(function () {
    DoAjaxSearch(0);
});

$('#searchToDate').change(function () {
    DoAjaxSearch(0);
});

function initSiteSearch() {
    DoAjaxSearch(0);
}

function DoAjaxSearch(pageNo) {
    var keywords = $("#searchboxSite").val();
    var fromDate = $("#searchFromDate").val();
    var toDate = $("#searchToDate").val();
    var orderBy = $("#orderField").val();
    var source = document.getElementById("searchTemplate").innerHTML;
    var searchHandleBarsTemplate = Handlebars.compile(source);

    $.ajax({
        url: "/Umbraco/Surface/SiteSearch/DoSiteSearch",
        method: 'post',
        data: { keywords: keywords, fromDate: fromDate, toDate: toDate, orderBy: orderBy }
    }).done(function (result) {
        console.log('done ', result);
      //  resolve(result);
        var html = searchHandleBarsTemplate(result);
        $('#search__results').html(html);
        // Order by drop down
        var selector = '#orderField option[value=' + orderBy + ']';
        $(selector).attr('selected', 'selected');
    }).fail(function (result) {
        console.log('Failed', result);
       // reject(result);
    });
}