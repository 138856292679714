//$('.panels-toolkitGrid').isotope({
//    // options...
//    itemSelector: '.grid-item'
//});

var $serviceTools = null;
var markers = [];
var mapRender = false;

function defer(method) {
    if (window.jQuery && typeof google === 'object' && typeof google.maps === 'object') {
        method();
    } else {
        setTimeout(function () { defer(method); }, 50);
    }
}

//defer(function () {

//    $serviceTools = $('.panels-services').isotope({
//        // options
//        itemSelector: '.services__panel',
//        layoutMode: 'fitRows',
//        percentPosition: true
//    });
//    console.log('init services isotope : %o', $serviceTools);
//});


function doServicesForServiceChange(resultSet) {
    // Setup the map

    var latlng = new google.maps.LatLng(55.862449, -4.265023);
    var mapOptions = {
        center: latlng,
        zoom: 10
    };
    map = new google.maps.Map(document.getElementById('mapPanel'), mapOptions);

    google.maps.Map.prototype.clearMarkers = function () {
        for (var i = 0; i < markers.length; i++) {
            markers[i].setMap(null);
        }
        markers = [];
    };

    populateResultsForServices(resultSet);

}
//$('.btn-filter__service').click(function (event) {
//    filter($(this).val());
//});

function populateResultsForServices(resultSet) {

    for (var c = 0; c < resultSet.length; c++) {
        var row = resultSet[c];
        //console.log("ROW: %o", row);
        if (row.Location.Lat !== 0 || row.Location.Lng !== 0) {

            addMarker(row);
        }
    }

    if (markers.length > 0) {
        fitMap();
    }
}

function addMarker(row) {

    //console.log(' Row : %o', row);

    var latlng = new google.maps.LatLng(row.Lat, row.Lng);

    var marker = null;

    var pinColor = "ff921e";

    var pinImage = new google.maps.MarkerImage("https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|" + pinColor,
        new google.maps.Size(21, 34),
        new google.maps.Point(0, 0),
        new google.maps.Point(10, 34));

    if (row.Lat === 0 || row.Lng === 0) {
        // Skip it - Don't want pins in the middle of the map
    } else {
        marker = new google.maps.Marker({
            position: latlng,
            lat: row.Lat, 
            lng: row.Lng,
            map: map,
            title: row.title,
            description: row.description,
            link: row.link,
            boardId: row.boardId,
            major: row.major,

            icon: pinImage,

            linkAnchor: 'sr_' + row.Id
        });
        marker.addListener('click', function () {
            var venPopUpTemplate = Handlebars.compile($(".servicePopup").html());
            var html = venPopUpTemplate(marker);
            var infowindow = new google.maps.InfoWindow({
                content: html
            });
            infowindow.open(map, marker);
        });
        //google.maps.event.addListener(marker, 'click', (function (marker) {
        //    return function () {
        //        //map.setZoom(17);
        //        //console.log('#' + marker.linkAnchor);
        //        //scrollToAnchor('#' + marker.linkAnchor);

        //        //window.location.href = row.Url;

        //        //console.log("Title: %o Postcode: %o Phone: %o Lat: %o Lng: %o ", marker.title, marker.postcode,marker.phone, marker.lat,marker.lng);

        //        var pop = $(".map__popup");
        //        pop.css('display', 'block');
        //        console.log("******************" + $(".servicePopup").html());
        //        var venPopUpTemplate = Handlebars.compile($(".servicePopup").html());
        //        var html = venPopUpTemplate(marker);
        //        $('#popup').html(html);
        //        console.log("MARKER: %o", marker);
        //        map.panTo(marker.getPosition());
        //        console.log("Marker position: %o",marker.getPosition());

        //        $(".button__close").click(function () {

        //            pop.css('display', 'none');
        //        });


        //    };
        //})(marker));

        markers.push(marker);

    }
}
function deleteMarkersService() {
    map.clearMarkers();
    markers = [];
}
function fitMap() {
    //  Create a new viewpoint bound
    var bounds = new google.maps.LatLngBounds();
    //  Go through each...
    markers.forEach(function (marker) {
       
        bounds.extend(marker.getPosition());



    });

    //  Fit these bounds to the map


    var shetlands = new google.maps.LatLng(60.1508795, -1.1553678000000218);
    bounds.extend(shetlands);
    map.fitBounds(bounds);
    map.setCenter(bounds.getCenter());

    
}
function deleteMarkers() {
    map.clearMarkers();
    markers = [];
}
function filter(boards) {
    var chosenFilters = [];
    console.log("Classes: %o", boards);
    //var chosenFilters = $('.btn-filter__service:checkbox:checked');
    $(".btn-filter__service:checkbox:checked").each(function () {

        chosenFilters.push($(this).val());
    });
    //currentFilter = board;
    //console.log("Chosen filters: %o", chosenFilters);
    deleteMarkersService();

    var selectedServices = [];
    var major = false;
    for (var i = 0; i < serviceChanges.length; i++) {
        console.log("BOARD ID: %o", serviceChanges[i].boardId);
        if (serviceChanges[i].boardId == 1167 && chosenFilters.includes("major")) {
            selectedServices.push(serviceChanges[i]);
        }
        if (chosenFilters.includes(serviceChanges[i].boardId)) {
            selectedServices.push(serviceChanges[i]);
        }

    }
    //console.log("********** SELECTED SERVICES %o ********", selectedServices);
    if (selectedServices.length === 0) {
        populateResultsForServices(serviceChanges);
    }
    else {
        populateResultsForServices(selectedServices);
    }

    //displayVenues(selectedServices);

}


function renderMap() {
    console.log("Render map again");

    var activeItems = $(".grid-item.major");
    console.log(activeItems);

    $.each(activeItems, function (index, value) {
        var markers = "";
        var zoomValue = 8;
        var zoomCount = 0;
        var items = $(value).find("input");
        console.log(items);
        $.each(items, function (index2, value2) {
            var thisItem = $(value2);
            var boardName = thisItem.attr("data-board");
            boardName = boardName.toLowerCase();

            markers = markers + "markers=" + thisItem.attr("data-lat") + "," + thisItem.attr("data-lng") + "&";
            zoomCount++;

            if (boardName === "highland" && zoomCount > 1) {

                zoomValue = 6;
            }
        });
        markers = markers.substring(0, markers.length - 1);
        var src = "https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyBefwUFvkb6PdNoxNe9MIuoXjRHb4ZLEqA&size=250x250&" + markers + "&zoom=" + zoomValue;
        $(value).find("img").attr("src", src);

        mapRender = false;
    });
}


function doServiceDetailsFilter(showClasses) {

    var classes = showClasses.replace(',', ', ');
    //console.log('Show classes : %o', classes);

    $('.panels-services .services__panel').addClass('hidden');
    //$('.panels-services .services__panel').find(showClasses).removeClass('hidden');
    $('.panels-services').find(classes).removeClass('hidden');
}

$('.btn-filter__service').click(function (event) {


    if (this.id !== "major") {
        $('#major').prop('checked', false);

        //$(this).prop('checked', true);
        $('#major').removeClass("active");
        $('.notmajordiv').show();

        if (mapRender === true) {
            //console.log("i am here");
            renderMap();
        }
    }


    var filterClasses = "";
    $('.btn-filter__service:checkbox:checked').each(
        function () {
            filterClasses += ".serviceID_" + $(this).val() + ",";
        }
    );



    if ($(this).hasClass('active')) {

        $(this).removeClass('active');

        if (filterClasses !== "") {
            filterClasses = filterClasses.substr(0, filterClasses.length - 1);
        }
        else {
            $('.notmajordiv').show();
        }

        //console.log('Filter classes 1 : %o', filterClasses);
        //$serviceTools.isotope({ filter: filterClasses });
        doServiceDetailsFilter(filterClasses);
    }
    else {
        $(this).addClass('active');
        if (filterClasses !== "") filterClasses = filterClasses.substr(0, filterClasses.length - 1);

        if ($('#major').is(':checked')) {

            console.log("MAJOR IS CHECKED");
            $('.btn-filter__service:checkbox:checked').removeClass("active");
            $('.btn-filter__service:checkbox:checked').prop('checked', false);


            $('#major').prop('checked', true);
            $('#major').addClass("active");
            filterClasses = "." + $(this).val();
            console.log("filterClasses **** : %o", filterClasses);

            //$serviceTools.isotope({ filter: filterClasses });
            doServiceDetailsFilter(filterClasses);
            $('.notmajordiv').hide();

            var activeItems = $(".grid-item.major");
            //console.log(activeItems);

            $.each(activeItems, function (index, value) {
                var markers = "";
                var zoomValue = 8;
                var zoomCount = 0;
                var items = $(value).find("input");
                console.log(items);
                $.each(items, function (index2, value2) {
                    var thisItem = $(value2);
                    var boardName = thisItem.attr("data-board");
                    boardName = boardName.toLowerCase();


                    //console.log(thisItem.attr("data-lat"));
                    //console.log(thisItem.attr("data-major"));
                    if (thisItem.attr("data-major") === "True") {
                        markers = markers + "markers=" + thisItem.attr("data-lat") + "," + thisItem.attr("data-lng") + "&";
                        zoomCount++;
                    }
                    if (boardName === "highland" && zoomCount > 1) {

                        zoomValue = 6;
                    }
                });
                markers = markers.substring(0, markers.length - 1);
                var src = "https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyDYSZhbJ6Hswd0yGSXDJYMHyre_stYlFI4&size=250x250&" + markers + "&zoom=" + zoomValue;
                $(value).find("img").attr("src", src);
                mapRender = true;
            });




        } else {
            //console.log('Filter classes else : %o', filterClasses);
            //$serviceTools.isotope({ filter: filterClasses });
            doServiceDetailsFilter(filterClasses);
        }

    }
    filter(filterClasses);
});





var serviceChangeLoaded = true;