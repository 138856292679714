$(document).ready(function () {

    // Override the umbraco forms version
    $(".umbraco-forms-form.feedbackform input[type=submit]").not(".cancel").unbind('click');

    //Intercept Submit button in order to make ajax call instead of a postback
    $('.umbraco-forms-form.feedbackform form').preventDoubleSubmission();
});

// jQuery plugin to prevent double submission of forms
jQuery.fn.preventDoubleSubmission = function () {
    $(this).on('submit', function (e) {
        e.preventDefault(); 

        var $form = $(this);

        if ($form.data('submitted') === true) {
            // Previously submitted - don't submit again
        } else {
            if ($form.valid()) {
                // Mark it so that the next submit can be ignored 
                $form.data('submitted', true);

                // Make ajax call form submission
                $.ajax({
                    url: $form.attr('action'),
                    type: 'POST',
                    cache: false,
                    data: new FormData(this),
                    processData: false,
                    contentType: false,
                    success: function (result) {
                        var thankYouMessage = $(result).find('.umbraco-forms-submitmessage').first();
                        //Handles edge case where Recaptcha wasn't checked

                        if (thankYouMessage.length == 0) {
                            $(result).find('.field-validation-error').each(function (i, v) {
                                window.alert($(v).text());
                            });

                            $form.data('submitted', false);
                        }
                        else {
                            $form.html(thankYouMessage);
                        }
                    } 
                });
            }
        }
    });

    // Keep chainability
    return this;
};
$(function () {
    
    //if (Modernizr.touchevents) {
    //    console.log("Touch events");
    //} else {
    //    console.log("No touch events");
    //}

    var techAssessment = $("#techAssessment").val();
    var speciality = $("#specialityValue").val();
    var hiddenField1 = $("#57904dd0-dbf2-406f-b230-cf39dfd02d4c");
    var hiddenField2 = $("#2311580a-00a2-4ee0-efb4-1cef0a2eeffd");
    console.log(hiddenField1);
    if (hiddenField1 != null) {
        hiddenField1.val(techAssessment)
    }
    if (hiddenField2 != null) {
        hiddenField2.val(speciality)
    }


    //function is_touch_device4() {

    //    var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

    //    var mq = function (query) {
    //        return window.matchMedia(query).matches;
    //    }

    //    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
    //        $("body").addClass("touch");
    //        return true;
    //    }

    //    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    //    // https://git.io/vznFH
    //    var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    //    return mq(query);
    //}

    //console.log(is_touch_device4());


    //if (navigator.userAgent.match(/iPad/i) && screen.width >= 810) {

    //    alert("This is an ipad");

    //}

//            var config = {
//                apiKey: 'c67e836b31187a2bf85bcdf7cd0426ee3f3d51b6',
//                product: 'COMMUNITY',
                
//            optionalCookies: [
//            {
//                name: 'non-essential',
//                    label: 'Non-essential cookies',
//                    initialState: "OPEN",
//                    description: 'Non-essential cookies allow us to gather insights into how our website is being used and allow us to deliver content and functionality from our video and social media sites.',
//                    cookies: ['_ga', '_gid', '_gat', '__utma', '__utmt', '__utmb', '__utmc', '__utmz', '__utmv','loc','uvc'],
//                    onAccept: function () {
//                        (function (i, s, o, g, r, a, m) {
//                        i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
//                            (i[r].q = i[r].q || []).push(arguments)
//                        }, i[r].l = 1 * new Date(); a = s.createElement(o),
//                            m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
//                        })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

//                        ga('create', 'UA-20219470-1', 'auto');
//                        ga('send', 'pageview'); 
//                // End Google Analytics
                        

//                    },
//                    onRevoke: function () {
                     
//                        window['ga-disable-UA-20219470-1'] = true;
//                    }
           
//            }
//    ],

//    position: 'RIGHT',
//                theme: 'LIGHT',
//                statement: {
//                    description: 'For more detailed information on the cookies we use, please check our',
//                    name: 'cookie information page',
//                    url: '/about-this-site/',
//                    updated: '26/01/2018'
//                }
                
//};

//CookieControl.load( config );

    // Add class if edge
    if (/Edge/.test(navigator.userAgent)) {
        $('body').addClass('ie ie--edge');
    }

    // Add class if IE 11
    var is_ie11 = navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1;
    if (is_ie11) {
        $('body').addClass('ie ie--11');
    }

    // Add class if IE10
    var is_ie10 = navigator.appVersion.indexOf("MSIE 10") !== -1;
    if (is_ie10) {
        $('body').addClass('ie ie--10');
    }

    // Add class if safari
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (is_safari) {
        $('body').addClass('body--safari');
    }





    $('a[href*="#"]')
        // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function (event) {
            // On-page links
            if (
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                &&
                location.hostname == this.hostname
            ) {
                // Figure out element to scroll to
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                // Does a scroll target exist?
                if (target.length) {
                    // Only prevent default if animation is actually gonna happen
                    event.preventDefault();
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 1000, function () {
                        // Callback after animation
                        // Must change focus!
                        var $target = $(target);
                        $target.focus();
                        if ($target.is(":focus")) { // Checking if the target was focused
                            return false;
                        } else {
                            $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                            $target.focus(); // Set focus again
                        };
                    });
                }
            }
        });



});

// $(window).on('load', function () {

//     // Remove loading window
//     setTimeout(function () {
//         $('.loading-overlay').addClass('loading-overlay--hide');
//         $('.homepage').addClass('homepage--active');
//         $('main').addClass('main--active');
//     }, 500);

// });
