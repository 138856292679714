$(function(){
        var cookie = $('.cookie-notice');

        if (Cookies.get('shtgCookie')) {
            cookie.hide();
        } else {
            cookie.show();
            $('.cookie-notice__close').click(function () {
                Cookies.set('shtgCookie', 'accepted', {
                    expires: 365
                });
                cookie.hide();
            });
        }
})